import React, { useRef, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { FiLogIn, FiMail } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup' // importando tudo que tem dentro do yup dentro de uma variável Yup
import api from '../../services/api'

import { Container, Content, AnimationContainer, Background } from './styles'


import { useToast } from '../../hooks/toast'
import getValidationErrors from '../../utils/getValidationErrors'

import logoImg from '../../assets/logo.svg'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface ForgotPasswordFormatData {
	email: string

}

const ForgotPassword: React.FC = () => {
	const [loading, setLoading] = useState(false)
	const formRef = useRef<FormHandles>(null)

	const { addToast } = useToast()

	const handleSubmit = useCallback(
		async (data: ForgotPasswordFormatData) => {
			try {
				setLoading(true)

				formRef.current?.setErrors({}) // sempre que entrar na função zera os erros de validação

				// schema vamos validar um objeto que tem o seguinte formato
				// ferramenta parecida com os plugins de validate do jquery
				const schema = Yup.object().shape({
					email: Yup.string()
						.required('E-mail obrigatório')
						.email('Digite um e-mail válido'),
				})
				await schema.validate(data, {
					abortEarly: false, // vai retornar todos os erros de uma vez só e não só o primeiro que ele encontrar
				})

				// recuperação de senha
				await api.post('/password/forgot', {
					email: data.email
				})

				addToast({
					type: 'success',
					title: 'E-mail de recuperação enviado',
					description: 'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de entrada'
				})

			} catch (err) {
				// estamos verificando se é algum erro de validação do yup
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err)
					formRef.current?.setErrors(errors)
					return
				}
				addToast({
					type: 'error',
					title: 'Erro na recuperação de senha',
					description: 'Ocorreu um erro ao tentar realizar a recuperação de senha, tente novamente',
				})
			} finally {
				// finally, executa depois que passar pelo try(deu tudo certo) ou pelo cath(tudo errado), ou seja, dando certo ou errado a requisição ele vai executar o finally
				setLoading(false)
			}
		},
		[addToast],
	)

	return (
		<Container>
			<Content>
				<AnimationContainer>
					<img src={logoImg} alt="GoBarber" />

					<Form ref={formRef} onSubmit={handleSubmit}>
						<h1>Recuperar senha</h1>
						<Input icon={FiMail} name="email" placeholder="E-mail" />
						<Button loading={loading} type="submit">Recuperar</Button>
					</Form>

					<Link to="/signup">
						<FiLogIn />
						Voltar ao login
					</Link>
				</AnimationContainer>
			</Content>
			<Background />
		</Container>
	)
}

export default ForgotPassword
