import React, { useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiArrowLeft, FiMail, FiUser, FiLock } from 'react-icons/fi'
import { FormHandles } from '@unform/core' // é uma tipagem com tadas as funções do Ref do Form
import { Form } from '@unform/web'
import * as Yup from 'yup' // importando tudo que tem dentro do yup dentro de uma variável Yup
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'

import { useToast } from '../../hooks/toast'

import { Container, Content, AnimationContainer, Background } from './styles'

import logoImg from '../../assets/logo.svg'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface SignUpFormData {
	name: string
	email: string
	password: string
}

const SignUp: React.FC = () => {
	const formRef = useRef<FormHandles>(null)
	const { addToast } = useToast()
	const history = useHistory()

	const handleSubmit = useCallback(
		async (data: SignUpFormData) => {
			try {
				formRef.current?.setErrors({}) // sempre que entrar na função zera os erros de validação

				// schema vamos validar um objeto que tem o seguinte formato
				// ferramenta parecida com os plugins de validate do jquery
				const schema = Yup.object().shape({
					name: Yup.string().required('Nome obrigatório'),
					email: Yup.string()
						.required('E-mail obrigatório')
						.email('Digite um e-mail válido'),
					password: Yup.string().min(6, 'No mínimo 6 dígitos'),
				})
				await schema.validate(data, {
					abortEarly: false, // vai retornar todos os erros de uma vez só e não só o primeiro que ele encontrar
				})

				await api.post('/users', data)

				history.push('/')

				addToast({
					type: 'success',
					title: 'Cadastro realizado!',
					description: 'Você já pode fazer seu logon no Gobarber',
				})
			} catch (err) {
				// estamos verificando se é algum erro de validação do yup
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err)
					formRef.current?.setErrors(errors)
					return
				}
				addToast({
					type: 'error',
					title: 'Erro no cadastro',
					description: 'Ocorreu um erro ao fazer cadastro, tente novamente',
				})
			}
		},
		[addToast, history],
	)

	return (
		<Container>
			<Background />
			<Content>
				<AnimationContainer>
					<img src={logoImg} alt="GoBarber" />

					<Form ref={formRef} onSubmit={handleSubmit}>
						<h1>Faça seu cadastro</h1>
						<Input icon={FiUser} name="name" placeholder="Nome" />
						<Input icon={FiMail} name="email" placeholder="E-mail" />
						<Input
							icon={FiLock}
							name="password"
							type="password"
							placeholder="Senha"
						/>
						<Button type="submit">Cadastrar</Button>
					</Form>

					<Link to="/">
						<FiArrowLeft />
						Voltar para logon
					</Link>
				</AnimationContainer>
			</Content>
		</Container>
	)
}

export default SignUp
