import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { isToday, format, parseISO, isAfter } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import DayPicker, {DayModifiers} from 'react-day-picker'
import 'react-day-picker/lib/style.css'

import { FiClock, FiPower } from 'react-icons/fi'
import { Container, Header, HeaderContent, Profile, Content, Schedule, Calendar, NextAppointment, Section, Appointment } from './styles'
import logoImg from '../../assets/logo.svg'
import { useAuth } from '../../hooks/auth'
import api from '../../services/api'
import { Link } from 'react-router-dom'

interface MonthAvailabilityItem {
	day: number
	available: boolean
}

interface Appointment {
	id: string
	date: string
	hourFormatted: string
	user: {
		name: string
		avatar_url: string
	}
}

const Dashboard: React.FC = () => {
	const [selectedDate, setSelectedDate] = useState(new Date())
	const [currentMonth, setCurrentMonth] = useState(new Date())

	const [monthAvailability, setMonthAvailability] = useState<MonthAvailabilityItem[]>([])

	const [appointments, setAppointments] = useState<Appointment[]>([])

	const { signOut, user } = useAuth()

	const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
		// !modifiers.disabled são dias não disponíveis e com isso não vamos permitir selecionar uma data que não seja viável
		if(modifiers.available && !modifiers.disabled) {
			setSelectedDate(day)
		}
	}, [])

	const handleMonthChange = useCallback((month: Date) => {
		setCurrentMonth(month)
	},[])

	useEffect(() => {
		api.get(`/providers/${user.id}/month-availability`, {
			params: {
				year: currentMonth.getFullYear(),
				month: currentMonth.getMonth() + 1, // o +1 é pq o mês no javascript começa com 0(zero)
			}
		}).then(response => {
			setMonthAvailability(response.data)
		})
	},[currentMonth, user.id])

	const disabledDays = useMemo(() => {
		const dates = monthAvailability
			.filter(monthDay => monthDay.available === false)
			.map(monthDay => {
				const year = currentMonth.getFullYear()
				const month = currentMonth.getMonth()
				return new Date(year, month, monthDay.day)
			})

		return dates

	},[currentMonth, monthAvailability])

	const selectedDateAsText = useMemo(() => {
		return format(selectedDate, "'Dia' dd 'de' MMM", {
			locale: ptBR
		})
	},[selectedDate])

	const selectedWeekDay = useMemo(() => {
		return format(selectedDate, 'cccc', {
			locale: ptBR
		})
	},[selectedDate])

	useEffect(() => {
		api.get<Appointment[]>('/appointments/me', {
			params: {
				year: selectedDate.getFullYear(),
				month: selectedDate.getMonth() + 1, // o +1 é pq o mês começa no zero
				day: selectedDate.getDate(),
			}
		}).then(response => {
			const appointmentsFormatted = response.data.map(appointment => {
				return {
					...appointment,
					hourFormatted: format(parseISO(appointment.date), 'HH:mm')
				}
			})
			setAppointments(appointmentsFormatted)
		})
	}, [selectedDate])

	const morningAppointments = useMemo(() => {
		return appointments.filter(appointment => {
			return parseISO(appointment.date).getHours() < 12
		})
	},[appointments])

	const afternoonAppointments = useMemo(() => {
		return appointments.filter(appointment => {
			return parseISO(appointment.date).getHours() >= 12
		})
	},[appointments])

	const nextAppointment = useMemo(() => {
		// isAfter vai pegar uma data que seja depois de outra, no caso a data do agendamento que seja depois de agora
		return appointments.find(appointment =>
				isAfter(parseISO(appointment.date), new Date())
			)
	},[appointments])

	return (
		<Container>
			<Header>
				<HeaderContent>
					<img src={logoImg} alt="GoBarber" />

					<Profile>
						<img src={user.avatar_url} alt={user.name}/>
						<div>
							<span>Bem-vindo,</span>
							<Link to="/profile"><strong>{user.name}</strong></Link>
						</div>
					</Profile>

					<button type="button" onClick={signOut}>
						<FiPower/>
					</button>
				</HeaderContent>
			</Header>

			<Content>
				<Schedule>
					<h1>Horário Agendados</h1>
					<p>
						{isToday(selectedDate) && <span>Hoje</span>}
						<span>{selectedDateAsText}</span>
						<span>{selectedWeekDay}</span>
					</p>

					{isToday(selectedDate) && nextAppointment && (
						<NextAppointment>
							<strong>Agendamento a seguir</strong>
							<div>
								<img src={nextAppointment.user.avatar_url} alt={nextAppointment.user.name}/>
								<strong>{nextAppointment.user.name}</strong>
								<span>
									<FiClock />
									{nextAppointment.hourFormatted}
								</span>
							</div>
						</NextAppointment>
					)}

					<Section>
						<strong>Manhã</strong>

						{morningAppointments.length === 0 && (
							<p>Nenhum agendamento neste período</p>
						)}

						{morningAppointments.map(appointment => (
							<Appointment key={appointment.id}>
								<span>
									<FiClock />
									{appointment.hourFormatted}
								</span>
								<div>
									<img src={appointment.user.avatar_url} alt={appointment.user.name}/>
									<strong>{appointment.user.name}</strong>
								</div>
							</Appointment>
						))}

					</Section>

					<Section>
						<strong>Tarde</strong>

						{afternoonAppointments.length === 0 && (
							<p>Nenhum agendamento neste período</p>
						)}

						{afternoonAppointments.map(appointment => (
							<Appointment key={appointment.id}>
								<span>
									<FiClock />
									{appointment.hourFormatted}
								</span>
								<div>
									<img src={appointment.user.avatar_url} alt={appointment.user.name}/>
									<strong>{appointment.user.name}</strong>
								</div>
							</Appointment>
						))}

					</Section>

				</Schedule>
				<Calendar>
					<DayPicker
						fromMonth={new Date()} // não deixa navegar por datas anteriores ao dia atual
						disabledDays={[
							{ daysOfWeek: [0,6]}, // desabilitando domingo e sábado
							...disabledDays
						]}
						modifiers={{
							available: { daysOfWeek: [1,2,3,4,5]} // modifiers, permite criar uma classe css(available é o nome da nossa classe), daysOfWeek = para os dias da semana
						}}
						onDayClick={handleDateChange} // o que acontece quando usuário clica em algum dia
						selectedDays={selectedDate}
						onMonthChange={handleMonthChange} // quando o usuário clicar na setinha para mudar o mês
						weekdaysShort={['D','S','T','Q','Q','S','S',]}
						months={[
							'Janeiro',
							'Fevereiro',
							'Março',
							'Abril',
							'Maio',
							'Junho',
							'Julho',
							'Agosto',
							'Setembro',
							'Outubro',
							'Novembro',
							'Dezembro',
						]}
					/>
				</Calendar>
			</Content>
		</Container>
	)

}

export default Dashboard
