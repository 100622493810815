import React, { createContext, useCallback, useState, useContext } from 'react'
import api from '../services/api'

interface User {
	id: string
	name: string
	email: string
	avatar_url: string
}

interface AuthState {
	token: string
	user: User
}

interface SignInCredentials {
	email: string
	password: string
}

interface AuthContextData {
	user: User
	signIn(credentials: SignInCredentials): Promise<void>
	signOut(): void
	updateUser(user: User): void
}

// o typescript não vai deixar utilizarmos um objeto vazio, porém podemos utilizar um hackzinho para burlar isso
// vamos passar "as AuthContextData" para nomear nosso objeto vazio e assim ele parar de acusar erro
const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
	const [data, setData] = useState<AuthState>(() => {
		const token = localStorage.getItem('@GoBarber:token')
		const user = localStorage.getItem('@GoBarber:user')

		if (token && user) {
			api.defaults.headers.authorization = `Bearer ${token}`
			return { token, user: JSON.parse(user) }
		}

		return {} as AuthState
	})

	const signIn = useCallback(async ({ email, password }) => {
		const response = await api.post('sessions', {
			email,
			password,
		})

		const { token, user } = response.data

		localStorage.setItem('@GoBarber:token', token)
		localStorage.setItem('@GoBarber:user', JSON.stringify(user))

		api.defaults.headers.authorization = `Bearer ${token}`

		setData({ token, user })
	}, [])

	const signOut = useCallback(() => {
		localStorage.removeItem('@GoBarber:token')
		localStorage.removeItem('@GoBarber:user')

		setData({} as AuthState)
	}, [])

	// Partial do typescript torna todos tipos de User como opcionais
	// Exemplo: data: Partial<User>
	const updateUser = useCallback((user: User) => {
		localStorage.setItem('@GoBarber:user', JSON.stringify(user))
		setData({
			token: data.token,
			user
		})
	},[setData, data.token])

	return (
		<AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
			{children}
		</AuthContext.Provider>
	)
}

function useAuth(): AuthContextData {
	const context = useContext(AuthContext)

	// se não tiver o component <AuthContext> envolvendo nossa aplicação, ele dá essa mensagem de erro
	// na aula de testes, ele retirou o if, falando que era desnecessário
	// if (!context) {
	// 	throw new Error('useAuth must be used within an AuthProvider')
	// }

	return context
}

export { AuthProvider, useAuth }
