import React from 'react'
import { useTransition } from 'react-spring'

import Toast from './Toast'

import { ToastMessage } from '../../hooks/toast'
import { Container } from './styles'

interface ToastContainerProps {
	messages: ToastMessage[]
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
	// o primeiro parâmetro são as menssagens
	// o segundo parâmetro é uma função que vai obter qual é a key da minha menssagem, ou seja, é uma função que vai passar por cada um das menssagens e retorna a informação única
	// o terceiro parâmetro é um objeto contendo minhas animações
	const messagesWidthTransitions = useTransition(
		messages,
		(message) => message.id,
		{
			from: { right: '-120%', opacity: 0 },
			enter: { right: '0%', opacity: 1 },
			leave: { right: '-120%', opacity: 0 },
		},
	)

	return (
		<Container>
			{messagesWidthTransitions.map(({ item, key, props }) => (
				// !!message.description se tiver description
				<Toast key={key} style={props} message={item} />
			))}
		</Container>
	)
}

export default ToastContainer
