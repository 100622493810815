import React, { ButtonHTMLAttributes } from 'react'

import { Container } from './styles'

// isso é a mesma coisa que interface, porém como não vamos sobrescrever nenhuma tipagem herdada e a interface ficou vazia, então esse é código mais curto para tipagem
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	loading?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => (
	<Container type="button" {...rest}>
		{loading ? 'Carregando...' : children}
	</Container>
)

export default Button
