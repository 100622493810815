import React from 'react'
// esse "as" é só para renomear para não ficar com o mesmo nome da nossa interface
import {
	RouteProps as ReactDOMRouteProps,
	Route as ReactDOMRoute,
	Redirect,
} from 'react-router-dom'

import { useAuth } from '../hooks/auth'

// estou pegando todas as propriedades de ReactDOMRouteProps e acrescentando uma nova chamada isPrivate
interface RouteProps extends ReactDOMRouteProps {
	isPrivate?: boolean
	component: React.ComponentType
}

//  colocando o isPrivate sendo por default false, caso ela não exista
// criei esse ...rest para pegar todas as propriedades restantes
const Route: React.FC<RouteProps> = ({
	isPrivate = false,
	component: Component,
	...rest
}) => {
	const { user } = useAuth()
	// se user existir significa que ele está logado

	// o location é para não perder o histórico de navegação
	return (
		<ReactDOMRoute
			{...rest}
			render={({ location }) => {
				return isPrivate === !!user ? (
					<Component />
				) : (
					<Redirect
						to={{
							pathname: isPrivate ? '/' : '/dashboard',
							state: { from: location },
						}}
					/>
				)
			}}
		/>
	)
}

// isPrivate (rota privada) / user (usuário autenticado)
// se for true / true = ok
// se for false / false = ok
// se for true / false = redirecionar para o login
// se for false / true = redirecionar para o dashboard

export default Route
