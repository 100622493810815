import React, { useRef, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup' // importando tudo que tem dentro do yup dentro de uma variável Yup

import { Container, Content, AnimationContainer, Background } from './styles'

import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import getValidationErrors from '../../utils/getValidationErrors'

import logoImg from '../../assets/logo.svg'

import Input from '../../components/Input'
import Button from '../../components/Button'

interface SignInFormatData {
	email: string
	password: string
}

const SignIn: React.FC = () => {
	const formRef = useRef<FormHandles>(null)

	const { signIn } = useAuth()
	const { addToast } = useToast()

	const history = useHistory()

	const handleSubmit = useCallback(
		async (data: SignInFormatData) => {
			try {
				formRef.current?.setErrors({}) // sempre que entrar na função zera os erros de validação

				// schema vamos validar um objeto que tem o seguinte formato
				// ferramenta parecida com os plugins de validate do jquery
				const schema = Yup.object().shape({
					email: Yup.string()
						.required('E-mail obrigatório')
						.email('Digite um e-mail válido'),
					password: Yup.string().required('Senha obrigatória'),
				})
				await schema.validate(data, {
					abortEarly: false, // vai retornar todos os erros de uma vez só e não só o primeiro que ele encontrar
				})
				await signIn({
					email: data.email,
					password: data.password,
				})

				history.push('/dashboard')
			} catch (err) {
				// estamos verificando se é algum erro de validação do yup
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err)
					formRef.current?.setErrors(errors)
					return
				}
				addToast({
					type: 'error',
					title: 'Erro na autenticação',
					description: 'Ocorreu um erro ao fazer login, cheque as credenciais',
				})
			}
		},
		[addToast, history, signIn],
	)

	return (
		<Container>
			<Content>
				<AnimationContainer>
					<img src={logoImg} alt="GoBarber" />

					<Form ref={formRef} onSubmit={handleSubmit}>
						<h1>Faça seu logon</h1>
						<Input icon={FiMail} name="email" placeholder="E-mail" />
						<Input
							icon={FiLock}
							name="password"
							type="password"
							placeholder="Senha"
						/>
						<Button type="submit">Entrar</Button>
						<Link to="/forgot-password">Esqueci minha senha</Link>
					</Form>

					<Link to="/signup">
						<FiLogIn />
						Criar conta
					</Link>
				</AnimationContainer>
			</Content>
			<Background />
		</Container>
	)
}

export default SignIn
