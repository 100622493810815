import React, {
	InputHTMLAttributes,
	useEffect,
	useRef,
	useState,
	useCallback,
} from 'react'
// importando InputHTMLAttributes para pegar todas as propriedades html do input
import { IconBaseProps } from 'react-icons'
// IconBaseProps são as propriedades que ícone pode ter
import { FiAlertCircle } from 'react-icons/fi'
import { useField } from '@unform/core'

import { Container, Error } from './styles'

// extends InputHTMLAttributes está adicionando todas as propriedades dentro da nossa interface, <HTMLInputElement> é o parâmetro da nossa tipagem para informa que é um input html
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	containerStyle?: object
	name: string
	// sobrescrevi o name, pq por default ele é opcional, agora colocamos como obrigatório
	icon: React.ComponentType<IconBaseProps>
	// <> pegando as propriedades do icone passando com parametro da tipagem
}

// definindo que icon é Icon, pq componente tem que iniciar com letra maíuscula para o react
const Input: React.FC<InputProps> = ({ containerStyle = {}, name, icon: Icon, ...rest }) => {
	const inputRef = useRef<HTMLInputElement>(null) // HTMLInputElement parametro de tipagem, para podermos utilizar dentro da função do handleInputBlur
	const { fieldName, defaultValue, error, registerField } = useField(name)

	const [isFocused, setIsFocused] = useState(false)
	const [isFilled, setIsFilled] = useState(false)

	const handleInputFocus = useCallback(() => {
		setIsFocused(true)
	}, [])

	const handleInputBlur = useCallback(() => {
		setIsFocused(false)
		setIsFilled(!!inputRef.current?.value)
		// mesma coisa só que em uma linha
		// se input tiver algum valor
		// if (inputRef.current?.value) {
		// 	setIsFilled(true)
		// } else {
		// 	setIsFilled(false)
		// }
	}, [])

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef.current,
			path: 'value',
		})
	}, [fieldName, registerField])

	return (
		<Container
			style={containerStyle}
			isErrored={!!error}
			isFilled={isFilled}
			isFocused={isFocused}
			data-testid="input-container">

			{/* se tiver icon &&=então exibe o componente Icon */}
			{Icon && <Icon size={20} />}
			<input
				onFocus={handleInputFocus}
				onBlur={handleInputBlur}
				defaultValue={defaultValue}
				ref={inputRef}
				{...rest}
			/>
			{/* se tiver erro, exibe o ícone */}
			{error && (
				<Error title={error}>
					<FiAlertCircle color="#c53030" size={20} />{' '}
				</Error>
			)}
		</Container>
	)
}

export default Input
