import React, { ChangeEvent, useCallback, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiMail, FiUser, FiLock, FiCamera, FiArrowLeft } from 'react-icons/fi'
import { FormHandles } from '@unform/core' // é uma tipagem com tadas as funções do Ref do Form
import { Form } from '@unform/web'
import * as Yup from 'yup' // importando tudo que tem dentro do yup dentro de uma variável Yup
import getValidationErrors from '../../utils/getValidationErrors'
import api from '../../services/api'

import { useToast } from '../../hooks/toast'

import { Container, Content, AvatarInput } from './styles'

import Input from '../../components/Input'
import Button from '../../components/Button'
import { useAuth } from '../../hooks/auth'

interface ProfileFormData {
	name: string
	email: string
	old_password: string
	password: string
	password_confirmation: string
}

const Profile: React.FC = () => {
	const formRef = useRef<FormHandles>(null)
	const { addToast } = useToast()
	const history = useHistory()

	const { user, updateUser } = useAuth()

	const handleSubmit = useCallback(
		async (data: ProfileFormData) => {
			try {
				formRef.current?.setErrors({}) // sempre que entrar na função zera os erros de validação

				// schema vamos validar um objeto que tem o seguinte formato
				// ferramenta parecida com os plugins de validate do jquery
				const schema = Yup.object().shape({
					name: Yup.string().required('Nome obrigatório'),
					email: Yup.string()
						.required('E-mail obrigatório')
						.email('Digite um e-mail válido'),
					old_password: Yup.string(),
					password: Yup.string().when('old_password', {
						is: val => !! val.length,
						then: Yup.string().required('Campo obrigatório'),
						otherwise: Yup.string()
					}),
					password_confirmation: Yup.string().when('old_password', {
						is: val => !! val.length,
						then: Yup.string().required('Campo obrigatório'),
						otherwise: Yup.string()
					}).oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
				})
				await schema.validate(data, {
					abortEarly: false, // vai retornar todos os erros de uma vez só e não só o primeiro que ele encontrar
				})

				const {name, email, old_password, password, password_confirmation } = data

				// Object.assign server para unir dois objetos, no caso ainda colocamos uma condicional
				const formData = Object.assign({
					name,
					email,
				}, old_password ? {
					old_password,
					password,
					password_confirmation,
				} : {})

				const response = await api.put('/profile', formData)

				updateUser(response.data)

				history.push('/dashboard')

				addToast({
					type: 'success',
					title: 'Perfil atualizado!',
					description: 'Suas informações do perfil foram atualizadas com secesso!',
				})
			} catch (err) {
				// estamos verificando se é algum erro de validação do yup
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err)
					formRef.current?.setErrors(errors)
					return
				}
				addToast({
					type: 'error',
					title: 'Erro na atualização',
					description: 'Ocorreu um erro ao atualizar o perfil, tente novamente',
				})
			}
		},
		[addToast, history, updateUser],
	)

	const handleAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		// new FormData é do javascript padrão, vamos utilizar assim, porque na nossa api, na rota de updateavatar utilizamos o multipart para enviar o arquivo
		if(e.target.files){
			const data = new FormData()
			data.append('avatar', e.target.files[0])

			api.patch('/users/avatar', data).then((response) => {
				updateUser(response.data)
				addToast({
					type: 'success',
					title: 'Avatar atualizado!'
				})
			})
		}
	}, [addToast, updateUser])

	return (
		<Container>
			<header>
				<div>
					<Link to="/dashboard"><FiArrowLeft/></Link>
				</div>
			</header>

			<Content>
					<Form
						ref={formRef}
						onSubmit={handleSubmit}
						initialData={{
							name: user.name,
							email: user.email
						}}
					>
					<AvatarInput>
							<img src={user.avatar_url} alt={user.name}/>
							<label htmlFor="avatar">
								<FiCamera />
								<input type="file" id="avatar" onChange={handleAvatarChange}/>
							</label>
						</AvatarInput>

						<h1>Meu perfil</h1>
						<Input icon={FiUser} name="name" placeholder="Nome" />
						<Input icon={FiMail} name="email" placeholder="E-mail" />
						<Input
							containerStyle={{ marginTop: 24}}
							icon={FiLock}
							name="old_password"
							type="password"
							placeholder="Senha atual"
						/>
						<Input
							icon={FiLock}
							name="password"
							type="password"
							placeholder="Nova senha"
						/>

						<Input
							icon={FiLock}
							name="password_confirmation"
							type="password"
							placeholder="Confirmar senha"
						/>
						<Button type="submit">Confirmar mudanças</Button>
					</Form>


			</Content>
		</Container>
	)
}

export default Profile
