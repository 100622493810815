import React, { useRef, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FiLock } from 'react-icons/fi'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup' // importando tudo que tem dentro do yup dentro de uma variável Yup

import { Container, Content, AnimationContainer, Background } from './styles'

import { useToast } from '../../hooks/toast'
import getValidationErrors from '../../utils/getValidationErrors'

import logoImg from '../../assets/logo.svg'

import Input from '../../components/Input'
import Button from '../../components/Button'
import api from '../../services/api'

interface ResetPasswordFormatData {
	password: string
	password_confirmation: string
}

const SignIn: React.FC = () => {
	const formRef = useRef<FormHandles>(null)

	const { addToast } = useToast()

	const history = useHistory()
	const location = useLocation()

	const handleSubmit = useCallback(
		async (data: ResetPasswordFormatData) => {
			try {
				formRef.current?.setErrors({}) // sempre que entrar na função zera os erros de validação

				// schema vamos validar um objeto que tem o seguinte formato
				// ferramenta parecida com os plugins de validate do jquery
				const schema = Yup.object().shape({
					password: Yup.string().required('Senha obrigatória'),
					password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Confirmação incorreta')
				})
				await schema.validate(data, {
					abortEarly: false, // vai retornar todos os erros de uma vez só e não só o primeiro que ele encontrar
				})

				const {password, password_confirmation} = data
				const token = location.search.replace('?token=','')

				if(!token){
					throw new Error()
					// com isso ele vai cair no catch
				}

				await api.post('/password/reset', {
					password,
					password_confirmation,
					token
				})

				history.push('/')
			} catch (err) {
				// estamos verificando se é algum erro de validação do yup
				if (err instanceof Yup.ValidationError) {
					const errors = getValidationErrors(err)
					formRef.current?.setErrors(errors)
					return
				}
				addToast({
					type: 'error',
					title: 'Erro ao resetar senha',
					description: 'Ocorreu um erro ao resetar sua senha, tente novamente.',
				})
			}
		},
		[addToast, history, location.search],
	)

	return (
		<Container>
			<Content>
				<AnimationContainer>
					<img src={logoImg} alt="GoBarber" />

					<Form ref={formRef} onSubmit={handleSubmit}>
						<h1>Resetar senha</h1>
						<Input
							icon={FiLock}
							name="password"
							type="password"
							placeholder="Nova senha"
						/>
						<Input
							icon={FiLock}
							name="password_confirmation"
							type="password"
							placeholder="Confirmação da senha"
						/>
						<Button type="submit">Alterar senha</Button>
					</Form>
				</AnimationContainer>
			</Content>
			<Background />
		</Container>
	)
}

export default SignIn
